<template>
  <div style="height: 100vh" v-loading="true" />
</template>

<script>
import { getAcIdForJob } from "@/api/reports";
import { readNotification } from "@/api/notifications";
import store from "@/store";
import { Message } from "element-ui";

export default {
  name: "reportJobGate",
  async created() {
    if (this.$route.params.from_link) {
      await readNotification({
        notification_message_id: this.$route.params.notification_message_id,
        user_id: this.$route.params.user_id,
        message_status: "READ"
      }).catch(err => {
        console.log(err);
      });
    }
    if (this.$route.params.job_id) {
      await getAcIdForJob(this.$route.params.job_id)
        .then(async ({ data }) => {
          let { ac_id, sp_id } = data;

          let val = await store.dispatch(
            "app/generateUserAccountSwitcherOptions",
            {
              sp_id,
              ac_id
            }
          );

          let { accountId } = val;

          if (ac_id !== accountId) {
            this.$message.error("permission denied");
            this.$router.push("/analyse/reports").catch(() => {});
          } else {
            this.$router
              .push({
                name: "reports",
                params: {
                  "active-tab": "report-instances",
                  job: this.$route.params.job_id
                }
              })
              .catch(() => {});
          }
        })
        .catch(err => {
          Message.error(err || "Has Error");
          this.$router.push("/analyse/reports").catch(() => {});
        });
    } else {
      this.$router
        .push("/analyse/reports?active-tab=report-instances")
        .catch(() => {});
    }
  }
};
</script>
